import React from "react"
import { graphql } from 'gatsby'

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Header from "../components/Header"
import Library from "../components/Library"
import InternalFooter from "../components/InternalFooter"

export default ({ data }) => {
  const page = data.strapiCenturionDigitalLibrary
  return (
    <Layout>
      <SEO metaTitle={page.metaTitle} />
      <Header />
      <div className="container container--med island--dubs text-center">
        <h1 className="h2">{page.title}</h1>
        <h2 className="h3" dangerouslySetInnerHTML={{__html: page.subtitle}} />
      </div>
      {page.publications &&
        <Library publications={page.publications} />
      }
      <InternalFooter links={page.internalFooterLinks} />
    </Layout>
  )
}

export const query = graphql`
  query {
    strapiCenturionDigitalLibrary {
      metaTitle
      title
      subtitle
      publications {
        title
        items {
          image {
           localFile{
             childImageSharp {
              fluid(maxWidth: 676) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          }
          title
          description
          linkUrl
          additionalLinkText
          additionalLinkUrl
          newWindow
        }
      }
      internalFooterLinks {
        linkText
        linkUrl
        newWindow
      }
    }
  }
`
