import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import Img from "gatsby-image/withIEPolyfill"
import { Carousel } from "react-responsive-carousel"

const Library = ({ publications }) => {
  return (
    <div className="library publications island">
      {publications.map( (publication, index) => {
        return (
          <div key={index} className="pub-section">
            <div className="container container--wide">
              <h2 className="h2 landmarkless island">{publication.title}</h2>
            </div>
            <Carousel centerMode showArrows={false} showStatus={false} showIndicators={false} showThumbs={false}>
              {publication.items.map( (item, index) => {
                return (
                  <div key={index} className="pub-card">
                    {item.newWindow &&
                      <a href={item.linkUrl} target="_blank" rel="noopener noreferrer">
                        <Img className="landmarklet" fluid={item.image.localFile.childImageSharp.fluid} alt="" />
                        <i className="icon-expand"></i>
                        <h3 className="h4 landmarkless">{item.title}</h3>
                      </a>
                    }
                    {!item.newWindow &&
                      <Link to={item.linkUrl} download>
                        <Img className="landmarklet" fluid={item.image.localFile.childImageSharp.fluid} alt="" />
                        <i className="icon-download"></i>
                        <h3 className="h4 landmarkless">{item.title}</h3>
                      </Link>
                    }
                    <p>{item.description}</p>
                    {item.additionalLinkText && item.additionalLinkUrl &&
                      <a href={item.additionalLinkUrl} target="_blank" rel="noopener noreferrer">
                        <p>{item.additionalLinkText}</p>
                      </a>
                    }
                  </div>
                )
              })}
            </Carousel>
          </div>
        )
      })}
    </div>
  )
}

Library.propTypes = {
  publications: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default Library
